import IconLinkedIn from '../assets/LinkedInBlue.png'
import IconEnvelope from '../assets/mail.png'
import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

function ContactForm() {
    const form = useRef();
    const checkbox = useRef();
    const [isSending, setIsSending] = useState(false);
    const [visibleConfirmation, setvisibleConfirmation] = useState(false);
    const [errorSend, setErrorSend] = useState(false);


    const sendEmail = (e) => {
      e.preventDefault();
    
      setIsSending(true)

      emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE, process.env.REACT_APP_EMAILJS_TEMPLATE, form.current, process.env.REACT_APP_EMAILJS_KEY)
        .then((result) => {

            setvisibleConfirmation(true)
            setIsSending(false)
            cleanForm(form.current)
            setTimeout(()=> {
                setvisibleConfirmation(false);
                setErrorSend(false)
            }, 10000)

        }, (error) => {

            setErrorSend(true)
            setvisibleConfirmation(true)
            setIsSending(false)
            setTimeout(()=> {
                setvisibleConfirmation(false);
                setErrorSend(false)
            }, 10000) 
            console.log(error.text);
        });
    };

    const cleanForm = (inputForm) => {
        inputForm.querySelectorAll('[clean]').forEach(element => {
            (element.type == "checkbox") ? element.checked = false : element.value = "";
        });
    }



    return (
        <div className="contactForm" id="contactForm">
            <div className="container">
                <div className="formRow">
                    <form ref={form} className="form" onSubmit={sendEmail}>
                        <input className="form-input" clean="true" type="text" name="subject" placeholder="Subject" required/>
                        <input className="form-input" clean="true" type="text" name="user_name" placeholder="Name" required/>
                        <input className="form-input" clean="true" type="mail" name="user_email" pattern="[^ @]*@[^ @]*" placeholder="your@email.com" required/>
                        <textarea className="form-input" clean="true" type="text" name="message" placeholder="Message" rows="5" required/>
                        {visibleConfirmation && <span className={`form-confirmation ${errorSend && "form-confirmation-error"}`}>{!errorSend ? "Thank You, We'll be in touch": "Something went wrong, please try again or choose another option"}</span>} 
                        <div className="form-sub">
                            <input ref={checkbox} type="checkbox" clean="true" name="checkbox" required/>
                            <p onClick={()=> {checkbox.current.checked ? checkbox.current.checked = false : checkbox.current.checked = true }}>I consent to the processing of my personal data provided in the above form for contact purposes by <span className="nowrap">Dev-mails&nbsp;Łukasz&nbsp;Bilski</span></p>
                        </div> 
                        <button type="submit" className="form-input-btn">
                            {isSending ? <span className="loader"></span> : <span>Send Message</span>}
                        </button>
                                               
                    </form>
                </div>
                <div className="infoRow">
                    <div className="about-contact">
                        <p>Message me using the icons below or the contact form</p>

                        <div className="about-icons">
                            <a href="https://www.linkedin.com/in/lbilski" target="_blank" className="about-icons-item link-img" onClick={(e) => e.stopPropagation()} style={{ backgroundImage: `url(${IconLinkedIn})` }}></a>
                            <a href="mailto:lukasz@dev-mails.com" className="about-icons-item link-img" onClick={(e) => e.stopPropagation()} style={{ backgroundImage: `url(${IconEnvelope})` }} ></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactForm;