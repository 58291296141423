import { useState } from 'react'
import { projects_data  } from '../Projects/projects_data'
import ProjectsPreview from './Projects_Preview';


function Projects () {
    const [activeProject, setActiveProject] = useState()
    
    return ( 
        <div className="container" id="projects">
            <div className="title ">
                <p className="font-semibold color-prim">Sample Projects</p>
            </div>
            
            <div className="projects">
                <div className="projects-item font-medium" onClick={() => setActiveProject()}>
                    <p><span className="color-prim">HTML</span> code</p>
                    <p className="color-prim">Accessibility</p>
                    <p>Reusable blocks</p>
                    <p><span className="color-prim">Responsive</span> <br />layout</p>
                </div>
                {projects_data.map((project) => 
                    <div className="projects-item" key={project.id} style={{backgroundImage: `url(${project.thumbnail})`}} onClick={() => setActiveProject(project)}></div>
                )}
            </div>
            {activeProject ? 
                <ProjectsPreview project={activeProject}/> :
                <div className="projects-preview projects-preview-description">

                    <div className="description">
                        <p className="mb-2">I am well acquainted with different email clients and their specific requirements. This allows me to create code that is compatible with platforms such as Gmail, Outlook, or Apple Mail. It is important to me that your emails are displayed correctly, regardless of the email client your recipients use.</p>
                        <p>If you are in search of a professional HTML Email Developer, I am ready to assist.</p>
                    </div>
                    <div className="short-info">
                    <p className="short-info_item">Responsive layouts</p>
                    <p className="short-info_item">Accesibility optimized</p>
                    <p className="short-info_item">Tested on EOD / Litmus</p>
                    <p className="short-info_item">Alligned with dark mode</p>
                    <p className="short-info_item">SFMC custom blocks</p>
                </div>
                </div>            
            }


        </div> 
        );
}

export default Projects ;