import Logo from '../assets/Logo.png'

function NavBar () {
    return ( 
        <div className="navbar">
            <div className="container navbar-items">
                <a href="#"><img className="navbar-logo" src={Logo} alt="Dev-mails Logo"/></a>
                <a className="navbar-item" href="#about">About</a>
                <a className="navbar-item" href="#projects">Projects</a>
                <a className="navbar-item" href="#contactForm">Contact</a>
            </div>
        </div>
     );
}

export default NavBar;