import IconLinkedIn from '../assets/LinkedInBlue.png'
import IconEnvelope from '../assets/mail.png'

function About() {
    return ( 
        <div className="about container">
            <div className="left-column">
                <p className="p-top">I will convert your layout into email HTML code. I always strive to adapt it to the latest standards and follow best practices in email marketing. My goal is to make sure your messages are readable, visually appealing, and responsive on all possible devices and email clients.</p>
                <p>Please feel free to explore my portfolio, where you can find examples of my work and the results I have achieved for my clients. If you have any questions or need further information, I am happy to provide answers.</p>
            </div>
            <div className="right-column">
                <div className="about-contact">
                    <p>Message me with any form you want</p>

                    <div className="about-icons">
                        <a href="#contactForm" className="about-icons-item btn">Contact Me</a>
                        <a href="https://www.linkedin.com/in/lbilski" target="_blank" className="about-icons-item link-img" onClick={(e)=> e.stopPropagation()} style={{backgroundImage: `url(${IconLinkedIn})`}}></a>
                        <a href="mailto:lukasz@dev-mails.com" className="about-icons-item link-img" onClick={(e)=> e.stopPropagation()} style={{backgroundImage: `url(${IconEnvelope})`}} ></a>
                    </div>
                </div>
            </div>
        </div>
     );
}

export default About; 