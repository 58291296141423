import React, {useEffect, useState, useRef} from 'react'

function ProjectsPreview ({project}) {
    const leftPreview = useRef()
    const rightPreview = useRef()
    const [lightImg, setLightImg] = useState()
    const [darkImg, setDarkImg] = useState()
    const [activePreview, setActivePreview] = useState(true)
    const [singleView, setSingleView] = useState(false)

    useEffect(() => {
        setLightImg(project.desktop_light)
        setDarkImg(project.desktop_dark)
        setActivePreview(true)
        resetWidth()
    },[project])

    const switchView = (view) => {

        if (view == "desktop"){
            setLightImg(project.desktop_light)
            setDarkImg(project.desktop_dark)
            setActivePreview(true)
        }
        else if (view == "mobile") {
            setLightImg(project.mobile_light)
            setDarkImg(project.mobile_dark)
            setActivePreview(false)
        }
    }

    const resetWidth = () => {
        leftPreview.current.classList.remove("projects-preview-mail__width0")
        rightPreview.current.classList.remove("projects-preview-mail__width0")
        setSingleView(false)
    }

    const switchWidth = (clickedPreview) => {
        if (window.innerWidth <= 768) {

            if(singleView) {
                resetWidth()
            } else {
                setSingleView(true)
    
                if(clickedPreview == "left") {
                    rightPreview.current.classList.add("projects-preview-mail__width0")
                }
                else if (clickedPreview == "right") {
                    leftPreview.current.classList.add("projects-preview-mail__width0")
                }
            }

        }

    }


    return ( 
        <div >
            <div className="projects-preview-menu">
                <button onClick={() => {switchView("desktop")}} className={`btn ${activePreview ? 'btn-active': ''}`}>Desktop</button>
                <button onClick={() => {switchView("mobile")}} className={`btn ${!activePreview ? 'btn-active': ''}`}>Mobile</button>
                {singleView && <button onClick={() => {resetWidth()}} className="btn">Double View</button>}
            </div>
            <div className="projects-preview-mail">                
                <div ref={leftPreview} onClick={() => {switchWidth("left")}} >
                    <img src={lightImg} className={activePreview ? 'max-width_600': 'max-width_320'} alt="" />
                </div>
                <div ref={rightPreview} onClick={() => {switchWidth("right")}} >
                    <img src={darkImg} className={activePreview ? 'max-width_600': 'max-width_320'} alt="" />
                </div>
            </div>
        </div>
     );
}

export default ProjectsPreview ;