const projects_data  = [
    {
        id: 1,
        thumbnail: '/projects/Project1.png',
        desktop_light: '/projects/Project1_DT.png',
        desktop_dark: '/projects/Project1_DT_Dark.png',
        mobile_light: '/projects/Project1_MB.png',
        mobile_dark: '/projects/Project1_MB_Dark.png',
    },
    {
        id: 2,
        thumbnail: '/projects/Project2.png',
        desktop_light: '/projects/Project2_DT.png',
        desktop_dark: '/projects/Project2_DT_Dark.png',
        mobile_light: '/projects/Project2_MB.png',
        mobile_dark: '/projects/Project2_MB_Dark.png',
    },
    {
        id: 3,
        thumbnail: '/projects/Project3.png',
        desktop_light: '/projects/Project3_DT.png',
        desktop_dark: '/projects/Project3_DT_Dark.png',
        mobile_light: '/projects/Project3_MB.png',
        mobile_dark: '/projects/Project3_MB_Dark.png',
    }
]

export {projects_data } ;