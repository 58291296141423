function Footer () {
    return ( 
        <div className="footer">
            <div className="container">
                Copyright © 2023 Dev-mails. All Rights Reserved
            </div>
        </div>
     );
}

export default Footer ;