import icons from '../assets/icons.png'

function Icons () {
    return ( 
        <div className="container">
            <img className="icons" src={icons} alt="" />
        </div>
     );
}

export default Icons ;