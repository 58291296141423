import React, { useState, useEffect } from 'react'
import Header0 from '../assets/Header_0.png'
import Header1 from '../assets/Header_1.png'
import Header2 from '../assets/Header_2.png'
import Header3 from '../assets/Header_3.png'
import Header4 from '../assets/Header_4.png'
import Header5 from '../assets/Header_5.png'
import Header6 from '../assets/Header_6.png'


function Header() {
    const [imgsLoaded, setImgsLoaded] = useState(false);

    useEffect(() => {


      const loadImg = (imgUrl) => {
        return new Promise((resolve) => {
          const image = new Image();
          image.src = imgUrl;
          image.onload = () => {
            resolve(imgUrl);
          }        
        })
      }
  
      Promise.all([
        loadImg(Header0),
        loadImg(Header1), 
        loadImg(Header2), 
        loadImg(Header3),
        loadImg(Header4),
        loadImg(Header5),
        loadImg(Header6),])
      .then(() => {setImgsLoaded(true)})
    },[])

    return ( 
        <div className="header">
            <div id="about" className="title container font-semibold">
                <p className="font-medium"><span className="color-prim">Hey!</span> I'm Łukasz</p>
                <p>HTML Email Developer</p>
            </div>
            <div className="images images-height">
            {imgsLoaded &&  
                <span>           
                    <img src={Header1} alt="" />
                    <img src={Header2} alt="" />
                    <img src={Header3} alt="" />
                    <img src={Header4} alt="" />
                    <img src={Header5} alt="" />
                    <img src={Header6} alt="" />
                    <img src={Header0} alt="" />   
                </span>         
            }
            </div>
        </div>
     );
}

export default Header;