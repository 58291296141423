import NavBar from "./components/NavBar";
import Header from "./components/Header";
import About from "./components/About";
import Projects from "./components/Projects/Projects";
import Icons from "./components/Icons";
import ContactForm from "./components/ContactForm";
import Footer from "./components/Footer";

function App() {
  return (
    <div id="App" className="App">
      <NavBar />
      <Header />
      <About />
      <Projects />
      <Icons />
      <ContactForm />
      <Footer />
    </div>
  );
}

export default App;
